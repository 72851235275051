import { defineAction } from '_utils/redux'
import * as messageServices from '_services/messages'
import humps from 'humps'

export const GET_MESSAGES_BY_SERVICE_ORDER_ID = defineAction('GET_MESSAGES_BY_SERVICE_ORDER_ID')
export const CREATE_MESSAGE = defineAction('CREATE_MESSAGE')
export const CREATE_HELP_REQUEST = defineAction('CREATE_HELP_REQUEST')
export const UPDATE_MASSAGE = defineAction('UPDATE_MASSAGE')
export const DELETE_MASSAGE = defineAction('DELETE_MASSAGE')
export const GET_MESSAGE_OPTIONS = defineAction('GET_MESSAGE_OPTIONS')
export const GET_MESSAGE_SUBJECT_OPTIONS = defineAction('GET_MESSAGE_SUBJECT_OPTIONS')
export const CREATE_APPLICANT_VALUATION = defineAction('CREATE_APPLICANT_VALUATION')
export const GET_RATING_REQUESTER = defineAction('GET_RATING_REQUESTER')
export const GET_ALL_NOTES = defineAction('GET_ALL_NOTES')
export const UPDATE_NOTE = defineAction('UPDATE_NOTE')
export const DELETE_MESSAGE_ATTACHMENT = defineAction('DELETE_MESSAGE_ATTACHMENT')
export const DELETE_PAYMENT_ANTICIPATION = defineAction('PAYMENT_ANTICIPATION')

export const getMessagesByServiceOrderId = (serviceOrderId, params) => (dispatch, getState) => {
  const data = messageServices.getMessagesByServiceOrderId(
    getState().user.authToken,
    serviceOrderId,
    params
  )
  dispatch({
    type: GET_MESSAGES_BY_SERVICE_ORDER_ID.ACTION,
    payload: data,
  })
  return data
}

export const getMessageOptions = () => (dispatch, getState) => {
  const data = messageServices.getMessageOptions(getState().user.authToken)
  dispatch({
    type: GET_MESSAGE_OPTIONS.ACTION,
    payload: data,
  })
  return data
}

export const getMessageSubjectOptions = params => (dispatch, getState) => {
  const { stepStatus, typeSubject } = params || {}
  const queryParams = humps.decamelizeKeys({
    stepStatus,
    typeSubject, // Including type_subject_id in the query parameters
  })
  const data = messageServices.getMessageSubjectOptions(getState().user.authToken)(queryParams)
  dispatch({
    type: GET_MESSAGE_SUBJECT_OPTIONS.ACTION,
    payload: data,
  })
  return data
}

export const createMessage = payload => async (dispatch, getState) => {
  const data = await messageServices.createMessage(getState().user.authToken, payload)

  dispatch({
    type: CREATE_MESSAGE.ACTION,
    payload: async () => data,
  })

  return data
}

export const createHelpRequest = payload => (dispatch, getState) => {
  const data = messageServices.createHelpRequest(getState().user.authToken, payload)

  dispatch({
    type: CREATE_HELP_REQUEST.ACTION,
    payload: async () => data,
  })

  return data
}

export const updateMessage = (messageId, payload) => async (dispatch, getState) => {
  const data = await messageServices.updateMessage(getState().user.authToken, messageId, payload)

  dispatch({
    type: UPDATE_MASSAGE.ACTION,
    payload: async () => data,
  })

  return data
}

export const deleteMessage = messageId => (dispatch, getState) => {
  return dispatch({
    type: DELETE_MASSAGE.ACTION,
    payload: messageServices.deleteMessage(getState().user.authToken, messageId),
    meta: { messageId },
  })
}

export const deleteMessageAttachment = (messageId, attachmentId) => (dispatch, getState) => {
  dispatch({
    type: DELETE_MESSAGE_ATTACHMENT.ACTION,
    payload: messageServices.deleteMessageAttachment(getState().user.authToken)(
      messageId,
      attachmentId
    ),
    meta: { attachmentId },
  })
}

/* Visto que esse metodo sera acessado por um interveniente no sistema que nao tem dados de acesso,
   precisamos criar um mecanismo pra que o mesmo possa acessar o sistema de forma a deixar a sua avaliacao
   na prestacao do servico. Pra tal manda-se um email para o solicitante com o auth token na url

    1- puxar o authorization que vem patente na url enviado por email ao solicitante
*/
// eslint-disable-next-line no-unused-vars
export const createApplicantValuation = (serviceOrderId, payload) => (dispatch, getState) => {
  return dispatch({
    type: CREATE_APPLICANT_VALUATION.ACTION,
    payload: messageServices.createApplicantValuation(
      new URLSearchParams(window.location.search).get('authorization'),
      // getState().user.authToken,
      serviceOrderId,
      payload
    ),
  })
}

export const getRatingRequester = ratingId => (dispatch, getState) => {
  const data = messageServices.getRatingRequester(getState().user.authToken, ratingId)
  dispatch({
    type: GET_RATING_REQUESTER.ACTION,
    payload: data,
  })
  return data
}

export const getAllNotes = params => (dispatch, getState) => {
  const queryParams = {
    serviceOrder: params?.serviceOrderId,
  }
  dispatch({
    type: GET_ALL_NOTES.ACTION,
    payload: messageServices.getAllNotes(getState().user.authToken)(queryParams),
    meta: queryParams,
  })
}

export const updateNote = (noteId, payload) => (dispatch, getState) => {
  dispatch({
    type: UPDATE_NOTE.ACTION,
    payload: messageServices.updateNote(getState().user.authToken)(noteId, payload),
    meta: payload,
  })
}

export const deletePaymentAnticipation = messageId => (dispatch, getState) => {
  return dispatch({
    type: DELETE_PAYMENT_ANTICIPATION.ACTION,
    payload: messageServices.deletePaymentAnticipation(getState().user.authToken, messageId),
    meta: { messageId },
  })
}
